<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"
          />
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import formEdit from './add'
import gql from "graphql-tag";
export default {
  components: {
    formEdit
  },
  data() {
    return {
      validationErrors: [],
      title: "Tạo thông báo",
      mode: 'add',
      models: {},
      componentKey: 0,
    }
  },
  async mounted () {
    this.mode = this.$route.name === 'editNotification' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Chỉnh sửa thông báo' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-notification' });
    },
    async saveData(params) {
      var that = this;
      if (params !== undefined && Object.entries(params).length > 0) {
        let objects = {
          'title': params.title,
          'plain_title' : this.$commonFuction.SkipVN(params.title),
          'content': params.content,
          'type': params.type.value,
          'type_id': params.type.id,
          'is_push': true,
          'action_key' : 'NOTIFICATION',
          'screen' : 'NOTIFICATION',
          'is_admin': true,
          'short_content' : params.content,
        }
        if(params.type.value == 'USER'){
          objects['uid'] = params.user.id
        }
        if (this.mode === 'add') {
          this.$apollo.mutate({
            mutation: DataServices.insertData('notification'),
            variables: {
              'objects': objects
            }
          }).then(async (dataNoti) => {
            if (dataNoti.data[Object.keys(dataNoti.data)].affected_rows) {
              var returning = dataNoti.data[Object.keys(dataNoti.data)].returning[0];
              var link_webview = that.$ConfigEvn.base_url_api+'/webview/notification/'+returning.id;
              var queryUpdateNoti = `
                mutation update_notification_multil($notification_object: notification_set_input) {
                  update_notification(where: { id: {_eq: "${returning.id}"}}, _set: $notification_object) {
                    affected_rows
                    returning {
                      id
                    }
                  }
                }
              `;
              this.$apollo.mutate({
                mutation: gql(queryUpdateNoti),
                variables: {notification_object: {webview_url :link_webview},}
              }).then(async () => {
                that.redirectAction();
              })
            }
          }).catch((error) => {
            console.error('ERROR: ', error)
            that.$toast.add({
              severity: 'error',
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
            })
            that.$commonFuction.is_loading(false);
          });
        }
        else if(this.mode === 'edit'){
          let objects = {
            'title': params.title,
            'plain_title' : this.$commonFuction.SkipVN(params.title),
            'content': params.content,
            'short_content' : params.short_content,
          }

          var id_notification = that.$route.params.id
          var queryUpdate = `
              mutation update_notification_multil($notification_object: notification_set_input) {
                update_notification(where: { id: {_eq: "${id_notification}"}}, _set: $notification_object) {
                  affected_rows
                  returning {
                    id
                  }
                }
              }
            `;
          let variables = {
            notification_object: objects,
          };
          this.$apollo.mutate({
            mutation: gql(queryUpdate),
            variables: variables
          }).then(async () => {
            that.$commonFuction.is_loading(false);
            that.redirectAction();
          })
        }
      }
    },
    async redirectAction() {
      var that = this;
      that.$commonFuction.is_loading(false);
      var router_back = {path: '/admin/list-notification'};
      // var urlRedirect = "/admin/list-notification";
      // if (!that.$commonFuction.isEmpty(that.page_transaction)){
      //  // router_back.query = { page_transaction: that.page_transaction.toString() };
      //   urlRedirect +="?page_transaction="+that.page_transaction.toString();
      //   //urlRedirect.searchParams.set('page_transaction', that.page_transaction.toString());
      // }
      that.$router.push(router_back);
      that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('notification'),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    },
  }
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:450px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 133px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 5px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
.p-field {
  margin-bottom: 0.5rem;
}
</style>