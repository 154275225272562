<template>
  <form id="frm_action">
  <div class="p-formgrid">
    <div class="p-card-field">
      <div class="p-field p-grid">
        <label class="p-col-fixed cus-label-title">Người nhận <span style="color:red">*</span></label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <Dropdown :disabled="mode == 'edit'" @change="dropdownChange($event)" v-model="types" :options="receiver" optionLabel="label"  class="p-col-width"/>
        </div>
      </div>
      <div class="p-field p-grid" v-if="types !== null && types.value === 'USER'">
        <label class="p-col-fixed cus-label-title">Người nhận cụ thể <span style="color:red">*</span></label>
      </div>
      <div class="p-field p-grid" v-if="types !== null && types.value === 'USER'">
        <div class="p-col vd-select">
          <v-select id= "a1" :disabled="mode=='edit'" :clearable="false" :filterable="false" placeholder="Chọn" v-model="selUser" :options="listUser" label="fullname" @search="fetchOptionsUser" class="p-col-width">
            <span slot="no-options" @click="$refs.select.open = false">
              Không có dữ liệu
            </span>
          </v-select>
          <input class="input_tmp_validator" id="validator_list_user">
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed cus-label-title">Tiêu đề <span style="color:red">*</span></label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <InputText v-model="title" placeholder="Nhập tiêu đề thông báo"  id="title_add" type="text" class="p-col-width" />
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed cus-label-title">Nội dung <span style="color:red">*</span></label>
      </div>
      <div class="p-field p-grid">
        <div class="p-col">
          <Textarea placeholder="Nhập mô tả" v-model="content" id="content_add" :autoResize="true" rows="4" class="p-col-width "/>
        </div>
      </div>
    </div>
    <Toolbar class="fixed-bottom-toolbar">
      <template slot="right">
        <Button
          :label="$constants.TEXT_BUTTON.BACK"
          @click="backToList()"
          class="p-button-warning"
        />
        <Button
          v-if="mode == 'edit'"
          :label="$constants.TEXT_BUTTON.EDIT"
          @click="saveData()"
        />
        <Button
          v-else
          :label="$constants.TEXT_BUTTON.ADD"
          @click="saveData()"
        />
      </template>
    </Toolbar>
  </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices';
// import CkditorCore from '../CkditorCore';
export default {
  // components: {
  //   CkditorCore
  // },
  props: {
    mode: String,
    model: Object,
    categories: Array,
    requests: Array
  },
  data() {
    return {
      title: '',
      notification_lang_id:null,
      content: '',
      users: [],
      lstUsers: [],
      lstAccounts: {},
      types: { value: 'ALL', label: 'Gửi đến tất cả' ,id :1},
      receiver: [{ value: 'ALL', label: 'Gửi đến tất cả' , id :1}, {value: 'USER', label: 'Gửi đến người nhận cụ thể' ,id :0}],
      validationErrors: {},
      short_content: '',
      listUser : [],
      selUser : null,
      listUserAll : [],
    }
  },
  mounted () {
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.model==",this.model)
      this.title = this.model.title
      this.content = this.model.content
      let label = ''
      let id = 0
      if(this.model.type == 'ALL'){
        label = 'Gửi đến tất cả'
        id = this.model.notification_type.id;
      }else{
        label = 'Gửi đến người nhận cụ thể'
        id = 0;
      }
      this.types =  {value: this.model.type, label:label, id : id }
      if(this.model.user !== undefined && this.model.user !== null){
        this.selUser = this.model.user
        this.listUser = [this.model.user]
      }
    }
  },
  methods: {
    async fetchOptionsUser(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        let {data} = await this.$apollo.query({
          query: DataServices.getList('users',{"fields" : "id fullname"}),
          variables: {
            where_key: {
              role : {_eq: 'user'},
              deleted: {_eq: false},
              plain_fullname:{_like: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            },
            orderBy:{fullname: 'asc'}
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        this.listUser = list
      }else{
        this.listUser = this.listUserAll
      }
    },
    async getListUser(){
      let {data} = await this.$apollo.query({
        query: DataServices.getList('users',{"fields" : "id fullname"}),
        variables: {
          where_key: {
            role : {_eq: 'user'},
            deleted: {_eq: false},
          },
          orderBy:{fullname: 'asc'}
        },
        fetchPolicy: "network-only"
      })

      var list = data[Object.keys(data)];
      this.listUser = list
      this.listUserAll = list
      // this.selUser = null;
    },
    async validateData() {
      if (this.$commonFuction.isEmpty(this.title)) {
        document.getElementById("title_add").setCustomValidity("Vui lòng nhập tiêu đề!");
      } else {
        document.getElementById("title_add").setCustomValidity("");
      }
      if (this.$commonFuction.isEmpty(this.content)) {
        document.getElementById("content_add").setCustomValidity("Vui lòng nhập nội dung");
      } else {
        document.getElementById("content_add").setCustomValidity("");
      }
      if (!this.$commonFuction.isEmpty(this.types) && this.types.value === 'USER') {
        //console.log("this.lstAccountsthis.lstAccounts:",this.lstAccounts)
        console.log("this.selUser",this.selUser)
        if (this.$commonFuction.isEmpty(this.selUser)) {
          document.getElementById("validator_list_user").setCustomValidity("Vui lòng chọn người nhận cụ thể!");
        } else {
          document.getElementById("validator_list_user").setCustomValidity("");
        }
      }

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        inpObj.reportValidity();
        return true;
      }
    },
    backToList() {
      this.$emit('back');
    },
    async saveData() {
     this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
       this.$commonFuction.is_loading(false);
        return
      }
      var params = {
        title: this.title,
        content: this.content,
        type: this.types,
        user: this.selUser,
        // short_content: this.short_content,
      }
      console.log("params",params)
      this.$emit('save', params);
      
    },
    onChange(event) {
      this.category_id = event.value.id;
    },
    dropdownChange(event) {
      this.types = event.value;
      if (this.types && this.types.value == 'USER'){
        this.getListUser()
      }
    },
    onFileClear(index) {
      if (this.images !== undefined && this.images.length > 0) {
        this.images.splice(index, 1);
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      if(files.length > 0) {
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [], arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }
          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            this.images = this.images.concat.apply(this.images, arrTemp);
          }
        }
      }
    },
  }
}
</script>
<style lang="scss">
/* html {
  scroll-padding-top: 100px;
} */
.vd-select{
  .vs__dropdown-toggle{
    min-height: 40px;
  }
}
</style>